












































































































































































































 .pages-center {
    margin: 20px 0 20px;
    text-align: center;
   }
.index {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 20px 0;
  .el-scrollbar {
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
  }
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .management-admin-header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
}

.select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  .select-left {
       display: flex;
        align-items: center;
        margin-bottom: 20px;
    .text {
      font-size: 14px;
      color: #333333;
      margin-right: 10px;
    }
    .resources-cascader {
      width: 420px;
      ::v-deep .el-input__inner {
        border-radius: 0;
      }
    }
  }
  .select-btn {
    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }
 
}
